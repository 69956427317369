<template>
  <div class="pb-2">
    <portal to="body-top">
      <customer-name title="" />
    </portal>
    <label class="font-medium-5 font-weight-bolder"> Credit Cards</label>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      :create-page-url="{ name: 'home-customers-financial-info-create' }"
      :create-btn-dashed="true"
      :is-searchable="false"
      trash-list-path="home-customers-financial-info-trash-list"
      :can-create="ACCESS_ABILITY_FINANCIAL_INFO"
      :can-show-trash-list="ACCESS_ABILITY_FINANCIAL_INFO"
      :table-config-options="tableConfigOptions()"
    >
      <template #cell(cardholder)="{ data }">
        {{ getBillingContactName(data.value) }}
      </template>
      <template #cell(card_type)="{ data }">
        {{ getCardType(data.value) }}
      </template>
      <template #cell(network)="{ data }">
        {{ data.value | capitalize }}
      </template>
      <template #cell(billing_address)="{ data }">
        {{ getBillingAddressName(data.value) }}
      </template>
      <template #cell(actions)="{ data }">
        <div class="text-nowrap">
          <feather-icon
            v-if="!data.item.is_default"
            icon="LTrashIcon"
            size="24"
            class="cursor-pointer mr-1 border-dotted defaultIconColor"
            @click="setInActive(data.item)"
          />
          <b-link :to="{ name: 'home-customers-financial-info-update', params: { finInfoId: data.item.id } }">
            <feather-icon
              icon="LEyeIcon"
              size="24"
              class="cursor-pointer mr-1 defaultIconColor border-dotted"
            />
          </b-link>
        </div>
      </template>
      <template #cell(is_default)="{ data }">
        <span
          :id="data"
          class="cursor-pointer d-flex align-items-center position-relative"
          style="width: max-content; height: 20px;  "
          :style="{pointerEvents: isLoadingOfSetDisabled ? 'none':'all'}"
          @click="setDefault(data)"
        >
          <b-form-checkbox
            :id="data.item.id.toString()"
            :checked="data.item.is_default"
            style="pointer-events: none"
            :disabled="isLoadingOfSetDisabled"
          />
          <b-spinner
            v-if="isSettling(data.item.id)"
            style="margin-bottom: 4px; left: 15px; top: 4px"
            class="ml-1 position-absolute"
            small
            label="Small Spinner"
            type="grow"
            variant="success"
          />
        </span>
      </template>
    </l-table>

    <label class="font-medium-5 font-weight-bolder"> Balance </label>
    <b-table
      :fields="balanceColumns"
      :items="[]"
      show-empty
      striped
      responsive
      :empty-text="$t('None found')"
    >
      <template #empty="">
        <div class="text-center">
          {{ $t('None found') }}
        </div>
      </template>
    </b-table>
    <div class="text-right font-weight-bolder">
      TOTAL: $0.00
    </div>
  </div>
</template>

<script>

import LTable from '@/views/components/LTable/LTable.vue'
import {
  BLink, BFormCheckbox, BSpinner, BTable,
} from 'bootstrap-vue'
import CustomerName from '../../../components/CustomerName.vue'
import config from '../config'

export default {
  name: 'FinancialInfoList',
  components: {
    LTable,
    CustomerName,
    BLink,
    BFormCheckbox,
    BSpinner,
    BTable,
  },
  data() {
    return {
      isLoadingOfSetDisabled: false,
      idOfSettledDefault: null,
    }
  },
  computed: {
    // tableConfigOptions() {
    //   return {
    //     data: {
    //       order_type: 'desc',
    //     },
    //   }
    // },
  },
  methods: {
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    tableConfigOptions() {
      return {
        endpoint: `${this.MODULE_NAME}/getActiveList`,
      }
    },
    setInActive(data) {
      this.confirmNotification(this, data, `${this.MODULE_NAME}/setInActive`,
        { text: 'It is possible to revert this', confirmButtonText: 'Yes, deactivate it' })
        .then(() => {
          this.refetchData()
        })
    },
    update(data) {
      this.$router.push({
        name: 'home-customers-financial-info-update',
        params: { finInfoId: data.id },
      })
    },
    getTableConfigOptions() {
      return {
        getData: response => response.data.data,
        getTotal: () => 0,
      }
    },
    getCardType(type) {
      const cardType = this.$store.getters['listModule/getCardType'](type)
      return cardType ? cardType.text : '&mdash;'
    },
    isSettling(id) {
      return this.isLoadingOfSetDisabled && this.idOfSettledDefault === id
    },
    setDefault(data) {
      const {
        item: { id },
      } = data
      this.isLoadingOfSetDisabled = true
      this.idOfSettledDefault = id
      this.sendNotification(
        this,
        {
          id,
        },
        `${this.MODULE_NAME}/setDefault`,
      )
        .then(() => {
          this.refetchData()
          this.isLoadingOfSetDisabled = false
        })
    },
    getBillingContactName(contact) {
      return `${contact.first_name} ${contact.last_name}`
    },
    getBillingAddressName(address) {
      return `${address.street}, ${address.city}, ${address.zip}`
    },
  },
  setup() {
    const MODULE_NAME = 'financialInfo'
    const { tableColumns, balanceColumns, ACCESS_ABILITY_FINANCIAL_INFO } = config()
    return {
      tableColumns,
      MODULE_NAME,
      balanceColumns,
      ACCESS_ABILITY_FINANCIAL_INFO,
    }
  },
}
</script>

<style scoped>

</style>
